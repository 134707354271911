import "./index.css"
import { useEffect, useRef, useState } from "react"
import { multasImages, payService } from "../../services/multas-service";
import ImageLoader from "../../helpers/ImageLoader";

const sin_opcion_pago = ["ESTUDIO"];
const sin_descuento = ["DESCARGO PRESENTADO", "CONVENIO"];

const Penalty = ({ infraction }) => {

  const [loadingMedia, setLoadingMedia] = useState(false);
  const [infraccion, setInfraccion] = useState(null)
  const [payment, setPayment] = useState(null)
  const form = useRef(null);

  useEffect(() => {
    if (infraction && (!sin_opcion_pago.includes(infraction.estado)) && !payment) {
      const monto = (sin_descuento.includes(infraction.estado) ? infraction.valor2 : infraction.valor1);

      payService({ amount: monto, descripcion: infraction.tipo, number: infraction.numero, id: infraction.id, state: infraction.estado })
        .then(data => {
          if (data) {
            const formPayment = <form ref={form} className="payForm" name="f" action="https://post.epagos.com.ar" method="post">
              <input type='hidden' name='convenio' value={data.convenio} />
              <input type='hidden' name='numero_operacion' value={data.numero_operacion} />
              <input type='hidden' name='id_moneda_operacion' value={data.id_moneda_operacion} />
              <input type='hidden' name='detalle_operacion' value={data.detalle_operacion} />
              <input type='hidden' name='detalle_operacion_visible' value={data.detalle_operacion_visible} />
              <input type='hidden' name='ok_url' value={process.env.REACT_APP_PAYMENT_SUCCESS_URL + infraction.numero} />
              <input type='hidden' name='error_url' value={process.env.REACT_APP_PAYMENT_ERROR_URL + infraction.numero} />
              <input type='hidden' name='token' value={data.token} />
              <input type='hidden' name='version' value={data.version} />
              <input type='hidden' name='operacion' value={data.operacion} />
              <input type='hidden' name='id_organismo' value={data.id_organismo} />
              <input type='hidden' name='base_url' value={data.base_url} />
              <input type='hidden' name='fp_permitidas' value={data.fp_permitidas} />
              <input name="monto_operacion" type="hidden" value={data.monto_operacion} />
              <input name="identificador_externo_2" type="hidden" value={data.identificador_externo_2} />
              <input name="identificador_externo_4" type="hidden" value={data.identificador_externo_4} />
            </form>
            setPayment(formPayment)
          } else {
            setPayment(null)
          }
        })
    }
  }, [infraction]);

  const pay = () => {
    form.current.submit();
  }
  const getInfraccionMedia = async (nro, patente) => {

    setLoadingMedia(true)

    const response = await multasImages(nro, patente);

    if (!response.code === 200 && response.message === "OK") {
      setLoadingMedia(false)
      alert("No se pudo procesar la consulta intente luego")
      return
    }

    const data = {
      nro,
      images: response.object.fotografias,
      videos: response.object.videos
    }
    setInfraccion(data);
    setLoadingMedia(false)
  }

  const cleanSelection = () => {
    setInfraccion(null);
  }

  return (
    <div className='Infraccion' key={infraction.id} >
      <div className="Infraccion-header">
        <div className="numero">{infraction.numero}</div>
        <div className="fecha">{infraction.fecha} {infraction.hora}</div>
      </div>
      <div className="Infraccion-body">
        <div className="tipo"><i className="fa-solid fa-signs-post"> </i> {infraction.tipo}</div>
        <div className="direccion"><i className="fa-solid fa-location-dot"> </i> {infraction.direccion}</div>
        <div className="vehiculo">
          <div><i className="fa-solid fa-car"></i> {infraction.marca}</div>
          <div>{infraction.modelo}</div>
          <div>Dominio: <strong>{infraction.patente} </strong></div>
        </div>
      </div>
      <div className="Infraccion-acciones">
        <div>
          <button className="Infraccion-button" onClick={() => getInfraccionMedia(infraction.numero, infraction.patente)}><i className="fa-solid fa-photo-film"></i></button>
          <small onClick={() => getInfraccionMedia(infraction.numero, infraction.patente)}>Imágenes</small>
        </div>
        {payment &&
          <div>
            <button className="Infraccion-button"><i className="fa-solid fa-credit-card" onClick={() => pay()}></i></button>
            <small onClick={() => pay()}>Pagar</small>
            {payment}
          </div>
        }
        {sin_opcion_pago.includes(infraction.estado) &&
          <div>
            <button className="Infraccion-button"><i className="fa-solid fa-credit-card" onClick={()=> window.open("https://rentasweb.lasheras.gob.ar/ords/f?p=204:4081:::NO::P4081_TIPO_BUSQ:DOC", "_blank")}></i></button>
            <small onClick={()=> window.open("https://rentasweb.lasheras.gob.ar/ords/f?p=204:4081:::NO::P4081_TIPO_BUSQ:DOC", "_blank")}>Pagar</small>
          </div>
        }
      </div>
      <div className="Infraccion-videos">
        {
          (infraccion && infraccion.videos) ? infraccion.videos.map(infraction => <>
            <p>
              <small className="center">Este video tiene una disponibilidad de tiempo limitada. Si el mismo deja de ser visible actualice su consulta</small>
            </p>
            <img key={infraction.orden} src={infraction.linkTmp} alt={`video-${infraction.orden}`}></img>
          </>
          )
            : loadingMedia && <ImageLoader />
        }
      </div>

      <div className="Infraccion-images">
        {
          (infraccion && infraccion.images) && infraccion.images.map(infraction => <img key={`${infraccion.nro}-${infraction.orden}`} src={`data:image/png;base64,${infraction.mediaBase64}`} alt={`imagen infraccion ${infraction.orden}`} />)
        }
      </div>

      {infraccion && <button className="ButtonBack" onClick={cleanSelection}>Volver</button>}

    </div>
  )
}

export default Penalty;