import "./index.css"
import { useEffect, useState } from "react"
import ReactjsAlert from "reactjs-alert";
import Penalty from "../Penalty";

const PenaltyCollection = ({ penalties }) => {
  const [infracciones, setInfracciones] = useState([]);
  const [loading, setLoading] = useState(false);
 
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    setLoading(true)
    setInfracciones(penalties)
    setLoading(false)

    return (() => {
      setInfracciones([])
    })
  }, [penalties])

  const restart = () => {
    window.location.reload(true);
  }

  return (
    <>
      {
        loading
          ? <h1 className='Loading'>Mostrando información ...</h1>
          :
          <>
            {infracciones.map(item => <Penalty key={item.id} infraction={item} />  )}
            {
              alert &&
              <ReactjsAlert
                status={alert.status}
                type={alert.type}   // success, warning, error, info
                title={alert.title}  // title you want to display
                button={alert.button}
                autoCloseIn={alert.autoCloseIn}
                Close={() => setAlert(null)}   // callback method for hide
              />

            }
            {
              ( infracciones && infracciones.length > 0 ) && <button className="ButtonNew" onClick={restart}> <i className="fa-solid fa-magnifying-glass"></i>  Nueva Consulta</button>
            }
            
          
          </>
      }
    </>
  )
}

export default PenaltyCollection