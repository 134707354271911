import "./index.css"
import { useState } from "react"
import { multaService } from "../../services/multas-service";
import ReactjsAlert from "reactjs-alert";
import Loader from "../../helpers/Loader";
import PenaltyCollection from "../PenaltyCollection/";

const Main = () => {
    const [patente, setPatente] = useState("");
    const [documento, setDocumento] = useState("");
    const [infracciones, setInfracciones] = useState([])
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null);

    const searchMultas = async () => {
        if (!patente && !documento) {
            const alert = {
                type: "error",
                title: "Ingrese patente o documento. O si lo desea ambos para realizar una búsqueda",
                status: true,
                button: "Aceptar",
                autoCloseIn: 3000
            }
            setAlert(alert)
            return
        }

        setLoading(true);

        const data = await multaService({ dominio: patente, documento });

        if (!data.code === 200 && data.message === "OK") {
            const alert = {
                type: "error",
                title: "No se pudo procesar la consulta intente más tarde",
                status: true,
                button: "Aceptar",
                autoCloseIn: 3000
            }
            setAlert(alert)
            return
        }

        const infracciones = data.object.map((item) => {
            return {
                numero: item.numero,
                tipo: item.tipoInfraccion,
                direccion: item.lugar,
                hora: item.horaCaptura,
                fecha: `${String(item.fechaCaptura).substring(6, 8)}/${String(item.fechaCaptura).substring(4, 6)}/${String(item.fechaCaptura).substring(0, 4)}`,
                patente: item.dominio,
                marca: item.vehiculoMarca,
                modelo: item.vehiculoModelo,
                id: item.id,
                estado: item.estadoActual,
                vencimiento1: item.documento.fechaVto,
                vencimiento2: item.documento.fechaVto2,
                valorUf: item.documento.valorUf,
                cantidadUf: item.documento.cantidadUf,
                valor1: item.documento.valorNeto,
                valor2: item.documento.valorBruto
            }
        })
        setInfracciones(infracciones)
        setLoading(false)

        if (!(infracciones.length > 0)) {
            const alert = {
                type: "success",
                title: "No se encontraron multas asociadas al Documento/Patente ingresados",
                status: true,
                button: "Aceptar",
                autoCloseIn: 3000
            }
            setAlert(alert)
        }
    }



    return (
        <main className="Main video-container">
            <video autoPlay muted loop>
                <source src="media/video.mp4" type="video/mp4" />
            </video>
            <section className="Query ">
                {(infracciones.length === 0)
                    ? <p>Para consultar si posee infracciones de tránsito ingrese alguno de los valores del siguiente formulario</p>
                    : <p className="title-infracciones">Infracciones</p>
                }
                <div className={`Form ${loading || (infracciones && infracciones.length > 0) ? 'Hide' : 'Show'}`}>

                    <label className="FormLabel" htmlFor="dominio">Dominio:</label>
                    <input type="text" name="dominio" id="dominio" className="FormInput" onChange={(e) => setPatente(e.target.value)}></input>

                    <label className="FormLabel" htmlFor="dominio">Documento:</label>
                    <input type="number" name="documento" id="documento" className="FormInput" onChange={(e) => setDocumento(e.target.value)}></input>

                    <button className="FormButton" onClick={searchMultas}><i className="fa-solid fa-magnifying-glass"></i> Consultar</button>
                </div>
            </section>
            <section className="Infracciones">
                {loading
                    ?
                    <>
                        <h2 className='Loading'>obteniendo información ...</h2>
                        <Loader />
                    </>
                    : <PenaltyCollection penalties={infracciones} />}
            </section>

            {
                alert &&
                <ReactjsAlert
                    status={alert.status}
                    type={alert.type}   // success, warning, error, info
                    title={alert.title}  // title you want to display
                    button={alert.button}
                    autoCloseIn={alert.autoCloseIn}
                    Close={() => setAlert(null)}   // callback method for hide
                />

            }

        </main>
    )
}

export default Main