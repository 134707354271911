import axios from "axios";

export async function multaService(params){
    try {
        const headers = {'Authorization': process.env.REACT_APP_API_KEY, 'Content-Type': 'application/json'};
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}multas`, params, {headers: headers });
        return response.data;
    }catch(e){
        console.error(e);
        return null;
    }
}

export async function multasImages(nro_infraccion, patente){
    try {
        const headers = {'Authorization': process.env.REACT_APP_API_KEY, 'Content-Type': 'application/json'};
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}files/${patente}/${nro_infraccion}`, {headers: headers });
        return response.data;
    }catch(e){
        console.error(e);
        return null;
    }
}

export async function payService(params){
    console.log('data')
    try {
        const headers = {'Authorization': process.env.REACT_APP_API_KEY, 'Content-Type': 'application/json'};
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}multas/generate/payment`, params, {headers: headers });
        return response.data;
    }catch(e){
        console.error(e);
        return null;
    }
}

