import { Link, useParams } from 'react-router-dom'
import { Buffer } from 'buffer';
import "./index.css"
import Header from '../Header';


const Payment = () => {
  const { type, status } = useParams()
  const decode = Buffer.from(status, 'base64').toString('ascii');
  const response = decode.split("|");
  const [ card_data, amount, transaccion, entity] = response


  if (type === 'success') {
    return (
      <>
        <Header />
        <div className='payment'>
          <h4>Operación Exitosa</h4>
          <h5>Nro. Transacción <span>{transaccion}</span></h5>
          <h5>Pago: <span>{card_data}</span></h5>
          <h5>Monto:  <span>{Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.')}</span></h5>
          <h5>Estado: <span>{entity}</span></h5>
          <Link  className="ButtonBack" to="/">Volver</Link>
        </div>
      </>
    )
  }


  return (
    <>
        <Header />
        <div className='payment'>
          <h4>No se pudo realizar la operación</h4>
          <h5>Nro. Transacción <span>{transaccion}</span></h5>
          <h5>Estado: <span>{entity}</span></h5>
          <Link  className="ButtonBack" to="/">Volver</Link>
        </div>
      </>
  )
}
export default Payment;