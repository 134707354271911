import './App.css';

import Payment from './components/Payment';
import { BrowserRouter, Routes, Route} from "react-router-dom"
import Home from './components/Home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/payment/:type/:status" element={<Payment />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
