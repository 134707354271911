import "./index.css"
import React from "react"
import ContentLoader from "react-content-loader"

const Loader = (props) => (
  <ContentLoader 
    className="ContentLoader"
    speed={2}
    width={400}
    height={200}
    viewBox="0 0 400 200"
    backgroundColor="#fff"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="17" rx="3" ry="3" width="380"  height="11" /> 
    <rect x="0" y="38" rx="3" ry="3" width="380"  height="11" /> 
    <rect x="0" y="58" rx="3" ry="3" width="380"  height="11" /> 
    <rect x="0" y="78" rx="3" ry="3" width="380"  height="11" /> 
  </ContentLoader>
)

export default Loader