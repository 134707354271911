import "./index.css";

const Header = () =>{

  return (
      <header className="Header">
          <img className="logo" src="/images/logo-blanco.png"/>
          <h1 className="title">Juzgado Vial - Consulta de Infracciones</h1>
      </header>
  )
}

export default Header;