import "./index.css"
import React from "react"
import ContentLoader from "react-content-loader"

const ImageLoader = (props) => (
  <ContentLoader 
    className="ContentLoader"
    speed={2}
    width={320}
    height={320}
    viewBox="0 0 320 320"
    backgroundColor="#fff"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="17" rx="10" ry="3" width="380"  height="300" /> 
  </ContentLoader>
)

export default ImageLoader